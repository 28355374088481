<template>
  <div>
    <DownloadPoliticaPrivacidade :arquivo="arquivoPdf" :nomeArquivo="nomeArquivo"/>
    <vue-pdf-app class="alturaPdf" :pdf="arquivoPdf" :config="config"></vue-pdf-app>
  </div>
</template>

<script>
import arquivoPdf from "@/assets/politica-privacidade/politicaDePrivacidadePortal.pdf";
import DownloadPoliticaPrivacidade from '@/views/pages/politica-privacidade/DownloadPoliticaPrivacidade.vue';

export default {
  components: {
    DownloadPoliticaPrivacidade
  },
  data() {
    return {
      arquivoPdf,
      nomeArquivo: 'Política de Privacidade - Portal.pdf', 
      config: {
        toolbar: false
      },
    }
  }
}
</script>
<style scoped>
.alturaPdf{
  height: 88vh;
}
.pdf-app.light {
  --pdf-app-background-color: #F8F8F8;
  }
</style>